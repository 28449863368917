<!--狀態，保險-->
<template>
  <section class="policy-detail" >
    <v-title title="Policy Details"></v-title>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <div>
        <div
          v-if="!isEndorsement"
          class="form-item">
          <span class="little-title">Policy Type</span>
          <el-form-item>
            <div class="flex">
              <v-button
                disabled
                :class="formData.policy_type==200?'button-theme-disable':''">New Policy</v-button>
              <v-button
                disabled
                :class="formData.policy_type==201?'button-theme-disable':''"
              >Renew Policy</v-button>
            </div>
          </el-form-item>
        </div>
        <!--        motor-->
        <el-row
          v-if="isRenew"
          :gutter="10"
        >
          <v-row-col>
            <v-row-col same >
              <div class="form-item">
                <span>Previous Insurer</span>
                <el-form-item >
                  <v-input
                    v-model="formData.previous_insurer"
                    :disabled="!showOther"
                  ></v-input>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col same >
              <div class="form-item">
                <span>Previous Policy Number</span>
                <el-form-item>
                  <v-input
                    v-model="formData.previous_policy_number"
                    :disabled="!showOther"></v-input>
                </el-form-item>
              </div>
            </v-row-col>
          </v-row-col>
        </el-row>
        <span
          v-if="!isEndorsement&!showOther"
          class="little-title">Insurer</span>
        <el-row
          v-if="!isEndorsement&&!showOther"
          class="row-bg"
          justify="space-around">
          <div class="form-item">
            <el-col
              v-for="(item,index) in newInsureOptions"
              :key="index"
              class="img-sm-and-down"
              :xs="10"
              :sm="12"
              :md="5"
              :lg="4"
              :xl="4"
            >
              <!--              <div>QBE</div>-->
              <div
                class="policy-insurer hand"
                :class="item.id==formData.company_id?'is-checked':''"
                @click="handlerInsurer(index,item.id,false)">

                <img
                  :src="item.logo"
                  alt="">
              </div>
            </el-col>
            <el-col
              class="img-sm-and-down"
              :xs="10"
              :sm="12"
              :md="5"
              :lg="4"
              :xl="4"
            >
              <div
                align="center"
                class="policy-insurer hand"
                :class="showOtherInsurer?'is-checked':''"
                @click="handlerInsurer(4,0,true)"
              >
                <span>Other Insurers</span>
              </div>
            </el-col>
          </div>
        </el-row>
        <el-row
          v-if="!isEndorsement&&!showOther&&showOtherCompany"
          :gutter="20"
          justify="space-around">
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12">
            <div
              class="form-item">
              <span  class="little-title">Other Insurers</span>
              <div>
                <el-form-item
                  prop="company_id">
                  <v-select
                    v-model.number="formData.company_id"
                    :list="companyList"
                    :disabled="isEdit"
                    @change="handlerDate"></v-select>
                </el-form-item>
              </div>
            </div>

          </el-col>
        </el-row>
        <!--        motor-->
        <el-row
          v-if="!isEndorsement&&showOther"
          :gutter="10"
        >
          <v-row-col>
            <v-row-col same >
              <div class="form-item">
                <span>Insurer</span>
                <el-form-item
                  prop="company_id">
                  <v-select
                    v-model.number="formData.company_id"
                    :list="companyAllList"
                    @change="handlerDate"></v-select>
                </el-form-item>
              </div>
              <div
                v-if="formData.company_id==0"
                class="form-item">
                <el-form-item>
                  <v-input v-model="formData.insurer"></v-input>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col same >
              <div class="form-item">
                <span>Insurer Type</span>
                <el-form-item prop="issue_date">
                  <v-input
                    v-model="formData.insurance_type"
                    :disabled="isEdit||isRenew"></v-input>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col same>
              <div class="form-item">
                <span>Product Name</span>
                <el-form-item prop="issue_date">
                  <v-input
                    v-model="formData.product_name"
                    :disabled="isEdit||isRenew"></v-input>
                </el-form-item>
              </div>
            </v-row-col>
          </v-row-col>
        </el-row>
        <div
          v-if="productID==10&&!isEndorsement"
          class="form-item">
          <span  class="little-title">Car Type</span>
          <div class="car-type-radio">
            <el-form-item prop="class_id">
              <v-radio-group
                v-model="formData.class_id"
                :border="true"
                :disabled="isEdit||isRenew"
                :list="carClassOptions"
                @change="hanlderChange"
              >
              </v-radio-group>
            </el-form-item>
          </div>
        </div>

        <div
          v-if="!isEndorsement"
          class="form-item">
          <span  class="little-title">Client Type</span>
          <div class="car-type-radio">
            <el-form-item prop="client_type">
              <v-radio-group
                v-model="formData.client_type"
                :border="true"
                :disabled="productCompany||isEdit||isRenew"
                :list="userTypeOptions"
                @change="handlerPolicyData"
              >
              </v-radio-group>
            </el-form-item>
          </div>
        </div>
        <el-row
          :gutter="20"
          justify="space-around">
          <!--          <el-col-->
          <!--            :xs="24"-->
          <!--            :sm="24"-->
          <!--            :md="12"-->
          <!--            :lg="12"-->
          <!--            :xl="12">-->
          <!--            <div class="form-item">-->
          <!--              <span>Issue Date</span>-->
          <!--              <el-form-item prop="issue_date">-->
          <!--                <v-date-picker-->
          <!--                  v-model="formData.issue_date"-->
          <!--                  @change="handlerPolicyData"></v-date-picker>-->
          <!--              </el-form-item>-->
          <!--            &lt;!&ndash;        <el-form-item>&ndash;&gt;-->
          <!--            &lt;!&ndash;          <v-date-picker></v-date-picker>&ndash;&gt;-->
          <!--            &lt;!&ndash;        </el-form-item>&ndash;&gt;-->
          <!--            </div>-->
          <!--          </el-col>-->
          <el-col
            v-if="productID==12"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12">

            <div class="form-item">
              <span  class="little-title">Plan Type</span>
              <div class="car-type-radio">
                <el-form-item prop="client_type">
                  <v-radio-group
                    v-model="formData.plan_id"
                    :border="true"
                    :list="planTypeOptions"
                    :disabled="isEdit||isRenew"
                    @change="handlerPlan"
                  >
                  </v-radio-group>
                </el-form-item>
              </div>
            </div>
          </el-col>
          <el-col
            v-if="productID==16"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12">

            <div class="form-item">
              <span  class="little-title">Period of Insurance</span>
              <div class="car-type-radio">
                <el-form-item prop="client_type">
                  <v-radio-group
                    v-model="formData.period_of_insurance"
                    :border="true"
                    :list="periodInsuranceOptions"
                    @change="handlerPeriod"
                  >
                  </v-radio-group>
                </el-form-item>
              </div>
            </div>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12">
            <div class="form-item">
              <span>Effective Date</span>
              <el-form-item prop="effective_date">
                <v-date-picker
                  v-model="formData.effective_date"
                  type="datetime"
                  @change="handlerDate"></v-date-picker>
              </el-form-item>
            </div>
          </el-col>
          <el-col
            v-if="productID==11||showTravel&&formData.plan_id==5"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12">
            <div class="form-item ">
              <span>Number of Day</span>
              <el-form-item prop="number_of_day">
                <v-input
                  v-model="formData.number_of_day"
                  type="number"
                  append="Days"
                  @change="handlerExpiryDate"></v-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12">
            <div class="form-item ">
              <span>Expiry Date</span>
              <el-form-item prop="expired_date">
                <v-date-picker
                  v-model="formData.expiry_date"
                  type="datetime"
                  :isTime="formData.plan_id!=5&&productID==12||isDisable||isUsed?false:true"
                  :disabled="isDisable"
                  @change="handlerDate"></v-date-picker>
              </el-form-item>
            </div>
          </el-col>
          <el-col
            v-if="isShowMaintenance"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12">
            <div class="form-item ">
              <span>Maintenance Period</span>
              <el-form-item prop="maintenance_period">
                <v-select
                  v-model="formData.maintenance_period_id"
                  :list="globalOptions.risk_period"
                ></v-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col
            v-if="productID==11"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12">
            <div class="form-item ">
              <span>Maintenance Period</span>
              <el-form-item prop="maintenance_period">
                <v-select
                  v-model="formData.maintenance_period_id"
                  :list="globalOptions.risk_period"
                ></v-select>
              </el-form-item>
            </div>
          </el-col>
          <div  v-if="showTravel">
            <!--            <el-col-->
            <!--              v-if="formData.plan_id==5"-->
            <!--              :xs="24"-->
            <!--              :sm="24"-->
            <!--              :md="12"-->
            <!--              :lg="12"-->
            <!--              :xl="12">-->
            <!--              <div class="form-item ">-->
            <!--                <span>Total Days</span>-->
            <!--                <el-form-item >-->
            <!--                  <v-input-->
            <!--                    :value="totalDay"-->
            <!--                    disabled></v-input>-->
            <!--                </el-form-item>-->
            <!--              </div>-->
            <!--            </el-col>-->
            <el-col
              v-if="formData.plan_id==5"
              :xs="24"
              :sm="24"
              :md="12"
              :lg="12"
              :xl="12">
              <div class="form-item ">
                <span>Destination</span>
                <el-form-item >
                  <v-input v-model="formData.destination"></v-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="12"
              :lg="12"
              :xl="12">
              <div class="form-item ">
                <span>No. of Person(s) to be insured</span>
                <el-form-item >
                  <v-input
                    v-model.number="formData.insured_person_numbers"
                    type="number"
                    :min="1"
                    @change="handlerPersonNO"></v-input>
                </el-form-item>
              </div>
            </el-col>
          </div>
        </el-row>

      </div>
    </el-form>
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {mapState,mapActions} from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'policyDetail',
  mixins: [MixinOptions,MixinRules],
  props:{
    form:{
      type: Object,
      default: ()=>({})
    },
    formDataLock: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      oldFormDataLock: -1,
      showOtherCompany: false,
      carSelect: 0,
      usageSelect:0,
      insurerIndex:0,
      maintenance_period_date:'',
      policy_no:'',
      policy_insurer:'',
      formData:{
        // carTypeID:0,
        carTypeName:'Private Car',
        policy_type: 200,//r
        company_id: 17,//r
        class_id: 1001,//r CLASS_PRIVATE_CAR = 1001|CLASS_COMMERCIAL_VEHICLE = 1002|CLASS_MOTORCYCLE = 1003|CLASS_MOTOR_TRADE = 1004
        client_type: 1,//r
        issue_date: '',
        effective_date: '',//r
        expiry_date:'',
        maintenance_period:'',
        maintenance_period_date_start:'',
        maintenance_period_date_end:'',
        number_of_day:'',
        maintenance_period_id:'',
        product_id:'',
        //travel
        plan_id:5,
        period_inusrance:1,
        insured_person_numbers_id:'',
        destination:'',
        period_of_insurance:1,
        insurance_type:'',
        product_name:'',
        insured_person_numbers:1,
        insurer:'',
        previous_insurer:'',
        previous_policy_number:''
      }
    }
  },
  computed:{
    ...mapState('quotation', ['productID','quoteInformationForm']),
    ...mapState('travel', ['travelerNo']),
    isMaintenancePeriod(){
      let product = [17,11]
      return product.includes(this.productID)
    },
    isEdit(){
      let {is_edit='',edit_all} = this.quoteInformationForm||{}
      if(edit_all)
        return false
      return is_edit?true:false
    },
    isEditAll(){
      let {is_edit='',edit_all} = this.quoteInformationForm||{}
      return edit_all
    },
    isRenew(){
      return   this.formData.policy_type ==201||this.$route.query.isRenew == 'true'
    },
    companyList(){
      let  length = this.globalOptions.company.length
      return this.globalOptions.company.slice(4,length)
    },
    companyAllList(){
      let  arr = this.globalOptions.company
      let arr_last = [{
        name:'Other Insurer',
        id:0
      }]
      return arr.concat(arr_last)
    },
    showOtherInsurer(){
      // let arr =  [2,3,4,5,0]
      let arr =  [17,3,12,15,0]
      return !arr.includes(this.formData.company_id)||this.insurerIndex==4
    },
    showContractors(){
      return this.productID ==11
    },
    isShowMaintenance(){
      let product = [17]
      return product.includes(this.productID)
    },
    showTravel(){
      return this.productID ==12
    },
    productCompany(){
      let product = [14,15,17,16,12]
      return product.includes(this.productID)
    },
    showOther(){
      return this.productID==18
    },
    totalDay(){
      let day= dayjs(this.formData.expiry_date).diff(dayjs(this.formData.effective_date),'day')
      day = day+1
      return day?Math.abs(day):0
    },
    isDisable(){
      // let product = [10,13,15,16,11,12,14]
      let product = [11,12]
      return product.includes(this.productID)
    },
    isDomesExpired() {
      let {expiry_date} = this.formData||{}
      const expiry = dayjs(expiry_date)
      const now = dayjs()
      return !now.isAfter(expiry)&&this.productID==16
    },
    isEndorsement()
    {
      return this.formData.policy_type ==203||this.$route.query.isEndorsement||false
    },
    isUsed(){
      let {order_status=0,edit_all} = this.quoteInformationForm||{}
      return order_status>10&&!edit_all
    },
    isQueryRenew(){
      return this.$route.query.isRenew =='true'
    },
    path(){
      return{

      }
    }
  },
  watch:{
    // 表單鎖，外層會更新，數值變化了才可以更新 formData
    formDataLock: {
      handler(val, old = -1) {
        if(val !== old) {
          this.oldFormDataLock = old
        }
      }
    },
    form:{
      immediate:true,
      handler(val){
        // if(this.formDataLock !== this.oldFormDataLock) {
        // 觸發更新就賦值，避免二次更新
        // this.oldFormDataLock = this.formDataLock
        Object.keys(this.formData).some(key => {
          if(key in val) {
            this.formData[key] = val[key]
            // // 用于匹配是否更新了信息
            // if(key in this.oldFormData) {
            //   this.oldFormData[key] = val[key]
            // }
          }
        })
        var date1 = dayjs(val.expiry_date).subtract(1,'day')
        var diffDay = date1.diff(val.effective_date,'day')
        if(this.isQueryRenew){
          this.formData.effective_date = dayjs(val.expiry_date).add(1,'day').format('YYYY-MM-DD 00:00')
          this.formData.expiry_date = dayjs(this.formData.effective_date).add(diffDay,'day').format('YYYY-MM-DD 23:59')
          this.formData.previous_insurer = this.getOptionName('globalOptions.company',val.company_id)
          if(this.showOther){
            this.formData.previous_insurer = this.formData.previous_insurer||this.formData.insurer
          }
        }


        // if(!issue_date)
        //   this.formData.issue_date = dayjs().format('YYYY-MM-DD')
        let {maintenance_period_date_start,maintenance_period_date_end} = val
        if(maintenance_period_date_start){
          this.maintenance_period_date = [maintenance_period_date_start,maintenance_period_date_end]
        }
      }
    },
    'formData.effective_date':{
      immediate:true,
      handler(val){
        let {period_of_insurance=1,number_of_day} = this.formData
        let time = dayjs(val).add(period_of_insurance,'year').subtract(1,'day').format('YYYY-MM-DD 23:59')
        if(this.formData.plan_id==5&&this.productID==12||this.productID==11)
        {
          console.log('number_of_day-',number_of_day)
          if(number_of_day>0)
            this.formData.expiry_date = dayjs(val).add(number_of_day,'day').subtract(1,'day').format('YYYY-MM-DD 23:59')
        }
        else{
          let can_edit = [10,13,14,15,16].includes(this.productID)
          let {expiry_date} = this.formData
          console.log('expiry_date-',expiry_date)
          this.formData.expiry_date =!this.isDisable?expiry_date:time
          console.log('this.formData.expiry_date -',this.formData.expiry_date )
          if(can_edit){
            if(expiry_date=='Invalid Date'||!expiry_date){
              this.formData.expiry_date = time
            }
          }
        }
        if(this.formData.plan_id==6)
          this.formData.expiry_date = time

      }
    },
    'formData.company_id':{
      immediate:true,
      handler(val){
        // this.showOtherCompany = ![2,3,4,5].includes(val)||val===0
        this.showOtherCompany = ![17,3,12,15].includes(val)||val===0
      }
    },
    productID:{
      immediate:true,
      handler(val){
        let product = [14,15,17]
        if(product.includes(val))
          this.formData.client_type=2
        if(val==16)
          this.formData.client_type=1
        if(val!=10){
          this.formData.class_id = Number(val+'01')
        }
      }
    },
    travelerNo:{
      immediate:true,
      handler(val) {
        this.formData.insured_person_numbers = val
      }
    }
    // '$route.query.isRenew':{
    //   immediate:true,
    //   handler(val){
    //     if(val)
    //     {
    //       console.log('val',val)
    //       this.formData.policy_type=201
    //     }
    //   }
    // },
  },
  created() {
    // if(this.productID==18)
    //   this.formData.company_id = 0
    let {path} = this.$route
    this.pathProductId.some(item=>{
      if(path.includes(item.name)){
        this.setProductID(item.id)
      }
    })
    this.handlerPolicyData()
    let ncdList = this.getNcdList(this.formData.class_id)

    this.setNcdList(ncdList)
  },
  methods:{
    ...mapActions('quotation', ['setNcdList','setNcd','setCompanyID','setProductID']),
    ...mapActions('travel', ['setTravelNo']),
    handlerPersonNO(){
      this.setTravelNo(this.formData.insured_person_numbers)
    },
    submitForm(formName){
      this.$refs.form.validate(valid => {
        if (valid) {
          let params = {
            maintenance_period_date_start:this.maintenance_period_date?this.maintenance_period_date[0]:'',
            maintenance_period_date_end:this.maintenance_period_date?this.maintenance_period_date[1]:'',
          }
          this.formData.product_id = this.productID
          this.$emit('getFormData', formName,{...this.formData,...params})
        }
      })
    },
    //提交给父类
    hanlderChange(){
      let {class_id} = this.formData
      this.formData.carTypeName = this.getOptionData('carClassOptions',class_id).name
      let ncdList = this.getNcdList(class_id)
      this.setNcdList(ncdList)
      this.setNcd(0)
      this.handlerPolicyData()
    },
    handlerPlan(){
      if(this.formData.plan_id==6){
        let val = this.formData.effective_date
        let time = dayjs(val).add(1,'year').subtract(1,'day').format('YYYY-MM-DD 23:59')
        this.formData.expiry_date =time
      }
      this.$emit('policyData',this.formData)
    },
    handlerPeriod(){
      let val = this.formData.effective_date
      let {period_of_insurance=1} = this.formData
      let time = dayjs(val).add(period_of_insurance,'year').subtract(1,'day').format('YYYY-MM-DD 23:59')
      this.formData.expiry_date =time
      this.$emit('policyData',this.formData)
    },
    handlerPolicyData(){
      this.setCompanyID(this.formData.company_id)
      this.$emit('policyData',this.formData)
    },
    handlerDate(){
      console.log('this.formData.',this.formData.company_id)
      this.handlerPolicyData()
    },
    handlerExpiryDate(){
      let {number_of_day,effective_date} = this.formData
      if(number_of_day>0&&effective_date&&number_of_day)
        this.formData.expiry_date = dayjs(effective_date).add(number_of_day,'day').subtract(1,'day').format('YYYY-MM-DD 23:59')
      else this.formData.expiry_date =''
    },
    handlerInsurer(index,id,showOther=false){
      // if(this.isEdit||this.isRenew)return
      if(this.isEdit)return
      this.insurerIndex = index
      // if(id)
      this.formData.company_id = id
      this.showOtherCompany = showOther
      this.handlerPolicyData()
    },
  }
}
</script>

<style scoped lang="scss">
  .policy-detail{
    .little-title{
      margin-bottom: 4px;
      margin-top: 20px;
      display: block;
      font-weight: 700;
      color: #2D3748;
    }
    .el-col{
      margin-right: 12px;
    }
    .policy-insurer{
        display: flex;
        justify-content: center;
        align-items: center;
        //height: 64px;
      width: 100%;
      height: 115px;
        margin-bottom: 10px;
      border: 1px solid #076081;
      box-sizing: border-box;
      border-radius: 4px;
      background: #fff;
      img{
        width: 70%;
        display: block;
      }
    }
    .is-checked{
      background: #E0F3FB;
      border: 2px solid #076081;
    }
    .car-type-radio{
      ::v-deep .v-radio-group{
        .el-radio-group{
          display: flex;
          flex-wrap: wrap;
          .el-radio__label{
            margin-left: 0;
            text-align: center;
          }
          .el-radio{
            margin-right: 12px;
            margin-bottom: 12px;
            color: #076081;
            border-color:#076081 ;
            font-weight: bold;
            background: #fff;
          }
          .el-radio__input{
            display: none!important;
          }
          .is-bordered{
            margin-left: 0;
          }
          .is-checked{
            background: #E0F3FB;
            color: #076081!important;
            border: 2px solid #076081;
            .el-radio__label{
              color: #076081!important;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
</style>
