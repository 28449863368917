export default {
  watch:{
    quoteInformationForm:{
      immediate:true,
      handler(val){
        let {client_type = 1, company_id = 0, coverage = {},class_id,remark} = val||{}
        if(val){
          this.formData = {...this.formData,...val}
          let {cover_type} = coverage
          this.carTypeName = class_id||1001
          this.clientType = client_type
          this.insurer = company_id
          this.cover_type = cover_type
        }
        this.formData.remark = remark||this.remarkInit
      }
    }
  },
  computed:{
    remarkInit(){
      return '此單最低消費HK$____。\n' +
          '如發生意外，必須於七日內向保險公司申報。\n' +
          'Minimum spending for this policy is HK$____.\n' +
          'In case of accident, it is a must to report to insurance company within 7 days.'
    }
  }
}
