<template>
  <section class="address-form">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      :disabled="disabled"
    >
      <slot name="extra"></slot>
      <span
        v-show="formType!==0"
        class="title">{{title}}</span>
      <span
        v-if="isTitle"
        class="title">Insured Location</span>
      <div class="form-flex">
        <el-row
          class="row-bg"
          :gutter="20"
          justify="space-around">
          <v-row-col >
            <el-row
              class="row-bg"
              :gutter="20"
              justify="space-around">
              <v-row-col
                :same="true"
                :same-number="8">
                <div class="form-item">
                  <span>Room/Flat</span>
                  <el-form-item prop="room">
                    <v-input
                      v-model="formData.room"
                      maxlength="100"/>
                  </el-form-item>
                </div>
              </v-row-col>
              <v-row-col
                :same="true"
                :same-number="8">
                <div class="form-item">
                  <span>Floor</span>
                  <el-form-item prop="floor">
                    <v-input
                      v-model="formData.floor"
                      maxlength="100"/>
                  </el-form-item>
                </div>
              </v-row-col>
              <v-row-col
                :same="true"
                :same-number="8">
                <div class="form-item">
                  <span>Block</span>
                  <el-form-item prop="block">
                    <v-input
                      v-model="formData.block"
                      maxlength="100"/>
                  </el-form-item>
                </div>
              </v-row-col>

              <v-row-col
                :same="true"
                :same-number="24">
                <div class="form-item">
                  <span>Building/Estate</span>
                  <el-form-item prop="building">
                    <v-input
                      v-model="formData.building"
                      maxlength="100"/>
                  </el-form-item>
                </div>
              </v-row-col>

              <v-row-col
                :same="true"
                :same-number="24">
                <div class="form-item">
                  <span>Street</span>
                  <el-form-item prop="street">
                    <v-input
                      v-model="formData.street"
                      maxlength="100"/>
                  </el-form-item>
                </div>
              </v-row-col>

            </el-row>
          </v-row-col>
          <v-row-col>
            <el-row
              class="row-bg"
              :gutter="20"
              justify="space-around">
              <v-row-col
                :same="true"
                :same-number="24">
                <div class="form-item">
                  <span>District</span>
                  <el-form-item prop="district">
                    <v-select
                      v-model="formData.district"
                      :list="globalOptions.area.district"
                      @change="changeDistrict" />
                  </el-form-item>
                </div>
              </v-row-col>
              <v-row-col
                :same="true"
                :same-number="24">
                <div class="form-item">
                  <span>Area</span>
                  <el-form-item prop="area">
                    <v-select
                      v-model="formData.area"
                      :list="globalOptions.area.area"
                      @change="changeArea" />
                  </el-form-item>
                </div>
              </v-row-col>
              <v-row-col
                :same="true"
                :same-number="24">

                <div class="form-item">
                  <span>Country/Region</span>
                  <el-form-item prop="region">
                    <v-select
                      v-model="formData.region"
                      :list="globalOptions.area.region"
                      @change="changeRegion" />
                  </el-form-item>
                </div>
              </v-row-col>
            </el-row>
          </v-row-col>
        </el-row>
      </div>

      <div
        v-if="formType === 0"
        class="form-flex">


      </div>
    </el-form>
  </section>
</template>
<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
// import {editAccount, sendPasswordRestList} from '@/api/account'
export default {
  name: 'AddressForm',
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formType: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    title:{
      type: String,
      default: 'Correspondence'
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isTitle: {
      type: Boolean,
      default: false
    },
    allRequired: {
      type: Boolean,
      default: false
    },
    notValid: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    isRules:{
      type:Boolean,
      default:true
    }
  },
  data() {
    const checkAddrRequired = (rule, value, callback) => {
      // const valueResult = value !== null && value !== ''
      // if(this.notValid) {
      //   callback()
      // }
      // if(this.allRequired && !valueResult) {
      //   callback(new Error(this.$t('placeholder.input')))
      // }
      // callback()
    }
    const checkRequired = (rule, value, callback) => {
      // const valueResult = value !== null && value !== ''
      // if(this.notValid) {
      //   callback()
      // }
      // if(!valueResult) {
      //   callback(new Error(this.$t('placeholder.input')))
      // }
      // callback()
    }
    return {
      oldFormDataLock: -1,
      formData: {
        room: null,
        floor: null,
        block: null,
        building: null,
        street: null,
        district: null,
        area: null,
        region: null
      },
      extraRules: {
        addr_room: [
          { validator: checkAddrRequired, trigger: ['change', 'blur'] }
        ],
        addr_floor: [
          { validator: checkAddrRequired, trigger: ['change', 'blur'] }
        ],
        addr_block: [
          { validator: checkAddrRequired, trigger: ['change', 'blur'] }
        ],
        addr_building: [
          { validator: checkAddrRequired, trigger: ['change', 'blur'] }
        ],
        addr_street: [
          { validator: checkRequired, trigger: ['change', 'blur'] }
        ],
        addr_district: [
          { validator: checkRequired, trigger: ['change', 'blur'] }
        ],
        addr_area: [
          { validator: checkRequired, trigger: ['change', 'blur'] }
        ],
        addr_region: [
          { validator: checkRequired, trigger: ['change', 'blur'] }
        ],
      }
    }
  },
  watch: {
    // 表單鎖，外層會更新，數值變化了才可以更新 formData
    formDataLock: {
      handler(val, old = -1) {
        if(val !== old) {
          this.oldFormDataLock = old
        }
      }
    },
    form: {
      immediate: true,
      deep: true,
      handler(val) {
        // if(this.formDataLock !== this.oldFormDataLock) {
        //   // 觸發更新就賦值，避免二次更新
        //   this.oldFormDataLock = this.formDataLock
        Object.keys(this.formData).some(key => {
          if(key in val) {
            this.formData[key] = val[key]
          }
        })
        // }
        // this.formData = JSON.parse(JSON.stringify(this.formData))
      }
    },
    formData:{
      deep:true,
      immediate:true,
      handler(val){
        let correspondence_address = {
          index:0,
          correspondence_address:{...val}
        }
        this.$emit('sameAddress', correspondence_address,0)
      }
    }
  },
  methods: {
    async submitForm(formName,index) {
      await new Promise((resolve, reject) => {
        this.$refs['form'].validate(valid => {
          if(valid) {
            let correspondence_address = {
              index:this.index,
              correspondence_address:{...this.formData}
            }
            this.$emit('getFormData', formName, correspondence_address,index)
            resolve(valid)
          } else {
            reject(valid)
          }
        })
      })
    },
    clearValidate() {
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
    },

    changeDistrict(id) {
      let { area, region } = this.formData
      const pid = this.getAreaData('district', id).pid
      // 檢查 area
      if(area !== pid) {
        this.formData.area = pid
      }
      // 檢查 region
      const areaPid = this.getAreaData('area', pid).pid
      if(region !== areaPid) {
        this.formData.region = areaPid
      }
    },
    changeArea(id) {
      let { region, district } = this.formData
      const pid = this.getAreaData('area', id).pid
      if(region !== pid) {
        this.formData.region = pid
      }
      const districtPid = this.getAreaData('district', district).pid
      if(districtPid !== id) {
        this.formData.district = ''
      }
    },
    changeRegion(id) {
      const areaPid = this.getAreaData('area', this.formData.area).pid
      if(areaPid !== id) {
        this.formData.area = ''
        this.formData.district = ''
      }
    },
    handlerPassword (id){
      this.network().sendPasswordRestList()
    },
    network() {
      return {
        sendPasswordRestList: async () => {
          const { data,status } = await sendPasswordRestList({customer_id:this.form.id})
          //data.message/data.message_lang
          if (status == 200)
          {
            this.$message({
              type: 'success',
              message: 'Success'
            })
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.address-form {
  background: #EDF2F7;
  border-radius: 8px;
  color: #2D3748;
  .form-flex{
    padding: 20px;
  }
  .mgb-5 {
    margin-bottom: 5px;
  }
  .form-block {
    margin-bottom: 15px;
    & > span:first-child {
      display: block;
      padding-bottom: 5px;
      color: #616266;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .title {
      display: block;
      padding: 20px 20px 0 20px;
      color: #000;
      font-size: 16px;
      font-weight: 500;

    }
    .form-flex {
      display: flex;
      justify-content: space-between;
      & > .form-block {
        margin-right: 15px;
        flex: 1;
        &:last-child {
          margin-right: 0;
        }
      }
    }
}
</style>
