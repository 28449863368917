<template>
  <div class="document">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <el-row
        class="row-bg"
        :gutter="20"
        justify="space-around">
        <!--        <v-row-col>-->
        <!--          <div class="form-item">-->
        <!--            <span>Date of Policy Receive</span>-->
        <!--            <el-form-item prop="policy_receive_date">-->
        <!--              <v-date-picker v-model="formData.policy_receive_date"></v-date-picker>-->

        <!--            </el-form-item>-->
        <!--          </div>-->
        <!--        </v-row-col>-->
        <!--      </el-row>-->
        <!--      <el-row-->
        <!--        class="row-bg"-->
        <!--        :gutter="20"-->
        <!--      >-->
        <!--        <v-row-col>-->
        <!--          <div class="form-item ">-->
        <!--            <span>Date of Policy Dispatch</span>-->
        <!--            <el-form-item prop="policy_dispatch_date">-->
        <!--              <v-date-picker v-model="formData.policy_dispatch_date"></v-date-picker>-->
        <!--            </el-form-item>-->
        <!--          </div>-->
        <!--        </v-row-col>-->
        <!--        <v-row-col>-->
        <!--          <div class="form-item ">-->
        <!--            <span>Dispatch Method</span>-->
        <!--            <el-form-item prop="policy_dispatch_mathed_id">-->
        <!--              <v-select-->
        <!--                v-model="formData.policy_dispatch_mathed_id"-->
        <!--                :list="mathedOptions"></v-select>-->
        <!--            </el-form-item>-->
        <!--          </div>-->
        <!--        </v-row-col>-->
        <v-row-col>
          <div class="form-item ">
            <span>{{isEndorsement?'Endorsement Number':'Policy Number'}}  </span>
            <el-form-item prop="policy_no">
              <v-input
                v-model="formData.policy_no"
                @change="handlerPolicyNumber"/>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col :xl="4">
          <div class="form-item ">
            <span>{{isEndorsement?'Endorsement':'Policy'}} </span>
            <el-form-item prop="policy_document_file_id">
              <file-container
                :edit="true"
                :fileData="{
                  url: formData.policy_document_file_url,
                  client_name: formData.policy_document_client_name
                }"
                :otherData="{
                  idKey: 'policy_document_id',
                  urlKey: 'policy_document_file_url',
                  client_name:'policy_document_client_name'
                }"
                @delete="handlerDeleteFile"
                @uploadSuccess="handlerUploadSuccess" />

            <!--              <v-upload v-model="formData.policy_document_file_id"></v-upload>-->
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col
          v-if="showMotor"
          :xl="4">
          <div class="form-item ">
            <span>Certificate of Insurance </span>
            <el-form-item prop="ci_file_id">
              <file-container
                :edit="true"
                :fileData="{
                  url: formData.ci_file_url,
                  client_name: formData.ci_file_client_name
                }"
                :otherData="{
                  idKey: 'ci_file_id',
                  urlKey: 'ci_file_url',
                  client_name:'ci_file_client_name'
                }"
                @delete="handlerDeleteFile"
                @uploadSuccess="handlerUploadSuccess" />

              <!--              <v-upload v-model="formData.policy_document_file_id"></v-upload>-->
            </el-form-item>
          </div>
        </v-row-col>
      </el-row>
      <el-row
        v-if="showMotor"
        class="row-bg"
        :gutter="20"
        justify="space-around">
        <v-row-col>
          <div class="form-item ">
            <span>{{isEndorsement?'Endorsement':''}}  Cover Note Number</span>
            <el-form-item prop="covernote_no">
              <v-input
                v-model="formData.covernote_no"
                @blur="handlerCheckCovernote"/>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col >
          <div class="form-item">
            <span>Cover Note</span>
            <el-form-item prop="covernote_document_file_id">
              <file-container
                :edit="true"
                :fileData="{
                  url: formData.covernote_document_file_url,
                  client_name: formData.covernote_document_file_client_name
                }"
                :otherData="{
                  idKey: 'covernote_document_id',
                  urlKey: 'covernote_document_file_url',
                  client_name:'covernote_document_file_client_name'
                }"
                @delete="handlerDeleteFile"
                @uploadSuccess="handlerUploadSuccess" />
              <!--              <v-upload v-model="formData.covernote_document_file_id"></v-upload>-->
            </el-form-item>
          </div>
        </v-row-col>
      </el-row>
      <div class="flex">
        <div class="flex-1">
          <template>
            <section>
              <div
                v-if="showMotor"
                class="hr-inline"></div>
              <div
                class="form-item">
                <span>Required Document</span>
                <el-form-item>
                  <v-checkbox-group
                    v-model="formData.required_documents"
                    :list="
                      documentList"
                  ></v-checkbox-group>
                </el-form-item>
              </div>

              <div class="flex-sb">
                <div class="form-item form-half document-file ">
                  <span>Documents</span>
                  <div
                    v-for="(item,index) in formData.documents"
                    :key="index"
                    class="flex theme-second-blue">
                    <i
                      class="el-icon-circle-close theme-second-blue"
                      @click="handlerDelete(index)"></i>
                    <span
                      class="hand "
                      @click="openFile(item.url)">{{  item.client_name}}</span>
                  </div>


                  <el-form-item>
                    <v-upload
                      ref="upload"
                      :multiple="true"
                      :limit="10"
                      @uploadSuccess="handlerDocumentSuccess"></v-upload>
                  </el-form-item>

                </div>
              </div>
              <div
                class="flex-sb">
                <div class="form-item">
                  <span>Full Set Doc. Received?</span>
                  <div class="car-type-radio">
                    <el-form-item >
                      <v-radio-group
                        v-model="formData.documents_received"
                        :border="true"
                        :list="yesNoOptions"
                      />
                    </el-form-item>
                  </div>
                </div>
              </div>
            </section>
            <!--            company-->
          </template>
        </div>

      </div>
    </el-form>
  </div>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {mapState,mapActions} from 'vuex'
import {checkPolicyNumber,checkCovernote} from '@api/policy'
export default {
  name: 'document',
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    classType:{
      type: Number,
      default: 0
    },
    carType:{
      type: Number,
      default: 0
    },
    clientType:{
      type: Number,
      default: 1
    }
  },
  data(){
    return{
      oldFormDataLock:-1,
      formData:{
        policy_no: '',
        covernote_no: '',
        policy_document_id: '',
        covernote_document_id: '',
        ci_file_id: '',
        policy_receive_date: '',
        policy_dispatch_date: '',
        policy_dispatch_mathed_id: '',//i //1 Collect /2 Mail /3 Keep in office /4 Other
        required_documents: [],
        documents:[],
        policy_document_file_url:'',
        policy_document_client_name:'',
        covernote_document_file_client_name:'',
        ci_file_client_name:'',
        ci_file_url:'',
        covernote_document_file_url:'',
        documents_received:0
      }
    }
  },
  computed:{
    ...mapState('quotation', ['productID','companyID','quoteInformationForm']),
    list(){
      return this.globalOptions.document_type
    },
    isEdit(){
      let {is_edit=''} = this.quoteInformationForm||{}
      return is_edit?true:false
    },
    isRenew(){
      let {isRenew=''} = this.$route.query||{}
      return isRenew=='true'
    },
    isCopy(){
      let {isCopy=''} = this.$route.query||{}
      return isCopy=='true'
    },
    documentList(){
      // let list = {
      //   10:this.documentOptions,
      //   11:this.contractorDocumentOptions,
      //   12:this.travelDocumentOptions,
      //   13:this.travelDocumentOptions,
      //   14:this.employeeDocumentOptions,
      //   15:this.businessDocumentOptions,
      //   16:this.travelDocumentOptions,
      //   17:this.conEmployeeDocumentOptions,
      //   18:this.travelDocumentOptions
      // }
      let result = []
      let arr =this.list
      arr.some(item=>{
        if (item.class_id == this.classType) {
          item.data.some(client=>{
            if(client.client_type===this.clientType)
            {
              result = client.items
              // return  this.clientType==1?true:false
            }
          })
        }
      })
      return result
      return list[this.productID]
    },
    showMotor(){
      return this.productID==10
    },
    isEndorsement()
    {
      return this.form.policy_type ==203||this.$route.query.isEndorsement=='true'
    },
    isQueryEndorsement(){
      return this.$route.query.isEndorsement=='true'
    }
  },
  watch:{
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        if(this.formDataLock!==this.oldFormDataLock)
        {
          this.oldFormDataLock = this.formDataLock
          Object.keys(this.formData).some(key => {
            if (key in val) {
              this.formData[key] = val[key]
            }
          })
          let {policy_document={},covernote_document={},ci_file} =val
          // if(this.isQueryEndorsement||this.isRenew||this.isCopy){
          if(this.isRenew||this.isCopy){
            this.formData.policy_no = ''
          }
          if(ci_file){
            let {url,file_id,client_name}=ci_file
            this.$set(this.formData,'ci_file_client_name',client_name)
            this.$set(this.formData,'ci_file_id',file_id)
            this.$set(this.formData,'ci_file_url',url)
          }
          if(policy_document&&!this.isQueryEndorsement&&!this.isRenew&&!this.isCopy){
            let {url,file_id,client_name}=policy_document
            this.$set(this.formData,'policy_document_client_name',client_name)
            this.$set(this.formData,'policy_document_id',file_id)
            this.$set(this.formData,'policy_document_file_url',url)
          }
          if(covernote_document&&!this.isRenew&&!this.isCopy){
            let {url,file_id,client_name}=covernote_document
            this.formData.covernote_document_file_url = url
            this.formData.covernote_document_file_client_name = client_name
            this.formData.covernote_document_id = file_id
          }
          if(this.isRenew||this.isCopy){
            this.formData.covernote_no = ''
            // this.formData.required_documents = []
            // this.formData.documents = []
            this.formData.documents_received = 0
          }
        }
      }
    },
    companyID:{
      immediate:true,
      handler(val){
        if(val&&!this.isEdit){
          this.handlerPolicyNumber()
          this.handlerCheckCovernote()
        }
      }
    }
  },
  methods:{
    handlerCheckCovernote(){
      if(this.isEndorsement||this.isRenew)
        return
      if(this.formData.covernote_no)
        this.network().checkCovernote()
    },
    handlerPolicyNumber(){
      if(this.isEndorsement||this.isRenew)
        return
      if(this.formData.policy_no)
        this.network().checkPolicyNumber()
    },
    submitForm(formName){
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('getFormData', formName, this.formData)
        }
      })
    },
    // file-container 組件刪除文件
    handlerDeleteFile(otherData) {
      this.$ConfirmBox({
        title: '',
        message: 'Are you sure you want to delete this policy?',
        confirmBtn:'Yes',
      }).then(() => {
        const { idKey, nameKey, urlKey,client_name } = otherData
        this.$set(this.formData, idKey, '')
        this.$set(this.formData, urlKey, '')
        this.$set(this.formData, nameKey, '')
        this.$set(this.formData, client_name, '')
      })

    },
    handlerUploadSuccess({ data, result, otherData }) {
      const {  idKey,  urlKey,client_name } = otherData
      const { file_id,  url,path } = data
      this.$set(this.formData, idKey, file_id)
      this.$set(this.formData, urlKey, url)
      this.$set(this.formData, client_name, path)
      this.$refs['form'].clearValidate([idKey])
    },
    handlerDelete(index){
      this.$ConfirmBox({
        title: '',
        message: 'Are you sure you want to delete this policy?',
        confirmBtn:'Yes',
      }).then(() => {
        this.formData.documents.splice(index,1)
        this.$refs.upload.clearFiles()
      })

    },
    handlerDocumentSuccess({ data, result, otherData }){
      const { file_id,path,url } = data
      this.formData.documents.push({file_id,client_name:path,url:url})

    },
    openFile(url){
      if(url){
        window.open(url,'_blank')
      }
    },
    network(){
      return{
        checkCovernote: async () => {
          let params = {
            covernote_no:this.formData.covernote_no,
            company_id:this.companyID,
          }
          let { data } = await checkCovernote(params)
          if(data.repeated)
            this.$ConfirmBox({
              title: '',
              message: '“Cover Note Number” has been stored in our system.Do you want to continue?',
              confirmBtn:'Yes',
              cancelBtn:'No',
              isCancel:true,
            }).then(() => {
            })
        },
        checkPolicyNumber: async () => {
          let params = {
            policy_no:this.formData.policy_no,
            company_id:this.companyID,
          }
          let { data } = await checkPolicyNumber(params)
          if(data.repeated)
            this.$ConfirmBox({
              title: '',
              message: '“Policy Number” has been stored in our system.Do you want to continue?',
              confirmBtn:'Yes',
              cancelBtn:'No',
              isCancel:true,
            }).then(() => {
            })
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .document{
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    .document-file{
      .flex{
        margin: 5px 0;
        align-items: center;
        span{
          font-weight: normal!important;
        }
        i{
          margin-right: 10px;
        }
      }
    }
    .hr-inline{
      margin: 15px 0;
    }
  }
</style>
