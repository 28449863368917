<template>
  <section class="policy-holder">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <!--      personal-->
      <el-row
        v-if="clientType===1"
        class="row-bg"
        :gutter="10"
        justify="space-around">
        <v-row-col>
          <v-row-col
            :same="true"
          >
            <div class="form-item">
              <span>Name</span>
              <el-form-item prop="policy_holder_name">
                <v-input
                  v-model="formData.policy_holder_name"
                  :disabled="checkDisabled('policy_holder_name')"
                  @change="handlerEmit"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="showMotor||showTravel||showDomestic"
            :same="true">
            <div class="form-item ">
              <span>Date of Birth</span>
              <el-form-item prop="policy_holder_birth">
                <v-date-picker
                  v-model="formData.policy_holder_birth"
                  :hasDeault="true"
                  :disabled="checkDisabled('policy_holder_birth')"
                  @change="handlerEmit"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col  :same="true">
            <div

              class="form-item ">
              <el-form-item
                prop="policy_holder_id_type"
                class="form-none-margin-bottom">
                <v-radio-group
                  v-model="formData.policy_holder_id_type"
                  :list="personalIdTypOptions"
                  @change="handlerEmit"/>
              </el-form-item>
              <el-form-item
                prop="policy_holder_id_no">
                <v-input
                  v-model="formData.policy_holder_id_no"
                  class="mg-t-5"
                  @change="handlerEmit"/>
              </el-form-item>
              <!--              <el-form-item-->
              <!--                v-else-->
              <!--              >-->
              <!--                <v-input-->
              <!--                  v-model="formData.pp_no"-->
              <!--                  class="mg-t-5"-->
              <!--                  @change="handlerEmit"/>-->
              <!--              </el-form-item>-->
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col>
          <v-row-col same>
            <div class="form-item ">
              <span>Mobile Number</span>
              <el-form-item prop="policy_holder_mobile">
                <v-input
                  v-model="formData.policy_holder_mobile"
                  :disabled="checkDisabled('policy_holder_mobile')"
                  @change="handlerEmit"/>
              </el-form-item>
            </div>
          </v-row-col>

          <v-row-col same>
            <div class="form-item ">
              <span>Email</span>
              <el-form-item prop="policy_holder_email">
                <v-input
                  v-model="formData.policy_holder_email"
                  @change="handlerEmit"/>
              </el-form-item>
            </div>
          </v-row-col>

          <v-row-col
            v-if="showMotor||showTravel||showHome||showDomestic||showOther"
            same>
            <div class="form-item ">
              <span>Occupation</span>
              <el-form-item>
                <v-input-select
                  v-model="formData.policy_holder_occ"
                  :valueKey="'name'"
                  :isFillin="true"
                  :list="globalOptions.occ"
                  @change="handlerEmit"
                />
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col
          v-if="carType!==1004&&showMotor"
          same>
          <v-row-col
            :same="true"
          >
            <div class="form-item">
              <span>Policyholder is one of the Named Driver?</span>
              <div class="car-type-radio">
                <el-form-item prop="policy_holder_is_driver">
                  <v-radio-group
                    v-model.number="formData.policy_holder_is_driver"
                    :border="true"
                    :list="yesNoOptions"/>
                </el-form-item>
              </div>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col
          v-if="showTravel"
          same>
          <v-row-col
            :same="true"
          >
            <div class="form-item">
              <span>Policyholder is one of the traveler?</span>
              <div class="car-type-radio">
                <el-form-item prop="policy_holder_is_driver">
                  <v-radio-group
                    v-model.number="formData.policy_holder_is_traveler"
                    :border="true"
                    :list="yesNoOptions"
                    @change="handlerEmit"/>
                </el-form-item>
              </div>
            </div>
          </v-row-col>
        </v-row-col>
      </el-row>
      <!--      company-->
      <el-row
        v-if="clientType===2"
        class="row-bg"
        :gutter="20"
        justify="space-around">
        <v-row-col>
          <!--          <v-row-col-->
          <!--            same-->
          <!--            :same-number="24">-->
          <!--            <span  class="holder-title">Policyholder</span>-->
          <!--          </v-row-col>-->
          <v-row-col
            same
            :same-number="24">
            <div class="form-item ">
              <span>Company Name</span>
              <el-form-item prop="holder_company_name">
                <v-input
                  v-model="formData.holder_company_name"
                  :disabled="checkDisabled('holder_company_name')"/>
              </el-form-item>
            </div>
          </v-row-col>

          <v-row-col
            same
            :same-number="24">
            <div class="form-item">
              <span>BR / CI Number</span>
              <el-form-item prop="holder_company_id_no">
                <v-input
                  v-model="formData.holder_company_id_no"
                  :disabled="checkDisabled('holder_company_id_no')"/>
              </el-form-item>
            </div>
          </v-row-col>

          <v-row-col
            same
            :same-number="24">
            <div class="form-item">
              <span>Company Phone Number</span>
              <el-form-item prop="holder_company_phone">
                <v-input
                  v-model="formData.holder_company_phone"
                  maxlength="8"
                  :disabled="checkDisabled('holder_company_phone')"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            same
            :same-number="24">
            <div class="form-item ">
              <span>Company Email</span>
              <el-form-item prop="holder_company_email">
                <v-input
                  v-model="formData.holder_company_email"
                  :disabled="checkDisabled('holder_company_email')"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            same
            :same-number="24">
            <div class="form-item">
              <span>Nature of Business</span>
              <el-form-item>
                <v-input-select
                  v-model="formData.holder_company_nature"
                  :list="globalOptions.industry "
                  :valueKey="'name'"
                  :isFillin="true"
                  :disabled="checkDisabled('holder_company_nature')"/>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col>
          <!--          <v-row-col-->
          <!--            same-->
          <!--            :same-number="24">-->
          <!--            <span class="holder-title">Contact Person</span>-->
          <!--          </v-row-col>-->
          <v-row-col
            same
            :same-number="24">
            <div class="form-item">
              <span>Contact Person Name</span>
              <el-form-item prop="holder_contact_name">
                <v-input
                  v-model="formData.holder_contact_name"
                  :disabled="checkDisabled('holder_contact_name')"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="!showContractor"
            same
            :same-number="24">
            <div class="form-item ">
              <span>Occupation & Position</span>
              <el-form-item prop="holder_contact_position">
                <v-input  v-model="formData.holder_contact_position"></v-input>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            same
            :same-number="24">
            <div class="form-item">
              <span>Phone Number</span>
              <el-form-item prop="holder_contact_phone">
                <v-input
                  v-model="formData.holder_contact_phone"
                  maxlength="8"
                  :disabled="checkDisabled('holder_contact_phone')"/>
              </el-form-item>
            </div>
          </v-row-col>

          <v-row-col
            same
            :same-number="24">
            <div class="form-item">
              <span>Email</span>
              <el-form-item prop="holder_contact_email">
                <v-input
                  v-model="formData.holder_contact_email"
                  :disabled="checkDisabled('holder_contact_email')"/>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>

      </el-row>
      <div class="address-content">
        <div class="card">
          <address-form
            ref="address-form"
            :formType="1"
            :title="'Correspondence Address'"
            :formDataLock="formDataLock"
            :form="formData.correspondence_address"
            @sameAddress="handlerSameAddress"
            @getFormData="getSubFormData"/>
        </div>
      </div>
    </el-form>
  </section>
</template>
<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import AddressForm from '@/views/components/address-form'
import IDCheck from '@/utils/idcheck'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'PolicyHolder',
  components: {
    AddressForm,
    // ConfirmInformation
  },
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    carType:{
      type: Number,
      default: 0
    },
    clientType:{
      type: Number,
      default: 1
    }
  },
  data() {
    const checkIdNo = (rule, value, callback) => {
      const { policy_holder_id_no,policy_holder_id_type } = this.formData
      if( policy_holder_id_type == 1 && !IDCheck(value)&&value) {
        callback(new Error('請輸入正確的身份證號碼'))
      }
      // else {
      //   if(!policy_holder_id_no && !value) {
      //     callback(new Error('請輸入正確的身份證號碼'))
      //   }
      // }
      callback()
    }
    const checkPPNo = (rule, value, callback) => {
      const { pp_no,policy_holder_id_type } = this.formData
      if(policy_holder_id_type===2&& !pp_no && !value) {
        callback(new Error('請輸入Passport'))
      }
      callback()
    }
    return {
      oldFormDataLock: -1,
      defautDate:'1980-01-01',
      formData: {
        /////policyholder
        policy_holder_name: '',//r
        policy_holder_mobile: '',//r
        policy_holder_email: '',
        policy_holder_id_no: '',
        policy_holder_id_type: 1,//1-hkid 2-passport 3-br
        policy_holder_occ_id: '',
        policy_holder_occ: '',
        policy_holder_birth: '',
        holder_company_name:'',
        holder_company_id_no:'',
        holder_company_email:'',
        holder_company_nature_id:null,
        holder_company_nature:'',
        holder_contact_name:'',
        holder_contact_email:'',
        holder_contact_phone:'',
        holder_contact_position:'',
        holder_company_phone:'',
        policy_holder_is_driver:'',
        policy_holder_is_traveler:'',//traveler
        pp_no:'',
        correspondence_address: {
          room: '',
          floor: '',
          block: '',
          building: '',
          street: '',
          district: '',
          area: '',
          region: ''
        },
      },
      subForm: {
        'address-form': false
      },
      extraRules: {
        policy_holder_id_no: [
          { validator: checkIdNo, trigger: [ 'blur'] },
          {required:false }
        ],
        // pp_no: [
        //   { validator: checkPPNo, trigger: ['blur'] }
        // ],

      }
    }
  },
  computed: {
    ...mapState('quotation', ['productID']),
    type() {
      // return this.formData.cust_typ
      return 1
    },
    showMotor(){
      return this.productID==10
    },
    showContractor(){
      return this.productID==11
    },
    showTravel(){
      return this.productID==12
    },
    showDomestic(){
      return this.productID==16
    },
    showHome(){
      return this.productID==13
    },
    showOther(){
      return this.productID==18
    },
    localAllOptions() {
      const classId = this.formData.class_id
      if (classId === 14) {
        return this.allOptions
      } else {
        return this[`allOptions${classId}`]
      }
    },
  },
  watch: {
    // 表單鎖，外層會更新，數值變化了才可以更新 formData
    formDataLock: {
      handler(val, old = -1) {
        if (val !== old) {
          this.oldFormDataLock = old
        }
      }
    },
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.formDataLock !== this.oldFormDataLock) {
          //   // 觸發更新就賦值，避免二次更新
          this.oldFormDataLock = this.formDataLock
          Object.keys(this.formData).some(key => {
            if (key in val) {
              this.formData[key] = val[key]
            }
          })
          // 給 pp_no 賦值
          const {policy_holder_id_type, policy_holder_id_no,pp_no} = val
          // if (policy_holder_id_type === 2 ) {
          //   this.formData.pp_no = policy_holder_id_no||pp_no
          // }
        }
        // 用于匹配是否更新了信息
        // this.oldFormData = JSON.parse(JSON.stringify(this.formData))
      }
    },
    'formData.policy_holder_is_driver': {
      deep: true,
      immediate: true,
      handler(val) {
        // if (this.formData.policy_holder_id_type === 2) {
        //   this.formData.policy_holder_id_no = this.formData.pp_no
        // }
        this.$emit('one-driver', val == 1 ? true : false,this.formData)
        // // this.$emit('change-driver', this.formData)
      },
    },
    'formData.policy_holder_id_no': {
      immediate: true,
      handler(val) {
        if (this.formData.policy_holder_id_type === 1) {
          this.formData.policy_holder_id_no = val.toLocaleUpperCase()
        }
        // // this.$emit('change-driver', this.formData)
      },
    },
  },
  methods: {
    handlerEmit(){
      const {policy_holder_is_driver,policy_holder_is_traveler} = this.formData
      // if (this.formData.policy_holder_id_type === 2) {
      //   this.formData.policy_holder_id_no = this.formData.pp_no
      // }
      this.$emit('one-driver', policy_holder_is_driver == 1 ? true : false,this.formData)
      this.$emit('one-traveler', policy_holder_is_traveler == 1 ? true : false,this.formData)

    },
    checkDisabled(field) {
      const {disable_filed = []} = this.form
      return disable_filed.includes(field)
    },
    openConfirmDialog() {
      this.$refs['confirm-information'].changeDialog()
    },
    handlerConfirmChangeInfo(result) {
      this.$emit('confirm', result)
    },
    getSubFormData(form, data) {
      this.subForm[form] = true
      this.formData = Object.assign(this.formData, data)
    },
    handlerSameAddress(data){
      // this.subForm['address-form'] = true
      // this.formData = Object.assign(this.formData, data)
      // console.log('data',data)
      if(data)
        this.$emit('address',data)
      // this.sameAddress()
    },
    sameAddress(){
      // 獲取表單組件數據
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      this.$emit('address',this.formData)
    },
    submitForm(formName,is_draft) {
      // 獲取表單組件數據
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          let params = this.formData
          // if (params.policy_holder_id_type === 2) {
          //   params.policy_holder_id_no = params.pp_no
          // }
          if(this.clientType==2){
            params.policy_holder_id_type=3
          }
          this.$emit('getFormData', formName, params)
        }else {
          if(is_draft)
          {
            this.$ConfirmBox({
              title: '',
              message: 'Please enter the policyholder name and mobile number',
              confirmBtn:'OK',
              isCancel:false
            }).then(() => {
              // this.network().draftQuotation()
            })
          }
        }
      })
      //
    },
  }
}
</script>
<style lang="scss" scoped>
.policy-holder {
  .address-content{
    margin-top: 20px;
  }
  .edit-holder-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;

    .card {
      padding-bottom: 40px;
    }
  }

  .title {
    display: block;
    padding-bottom: 15px;
    color: #616266;
    font-size: 20px;
    font-weight: 500;
  }

  .form-flex {
    display: flex;
    justify-content: space-between;

    & > .form-block {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .form-block {
    margin-bottom: 15px;

    & > span:first-child {
      display: block;
      padding-bottom: 5px;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .holder-title{
    margin-bottom: 16px;
    display: block;
  }
}
</style>
